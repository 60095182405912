<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content
                @add="addMachine"
                @delete="deleteMachine($event)"
                :rules="rules"
                :forms="forms"
                :readOnly="!isEdit"
                :formItems="showPopForms"/>
            </a-modal>
         <a-modal 
            title="审批" 
            :confirm-loading="confirmLoading" 
            @ok="check"
            v-model:visible="showCheckDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="false"
                :formItems="checkForms"/>
            </a-modal>
        <m-form
            :formItems="formItems"
            @createShop="createShop" 
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="pageChange($event)"
            @modifyStatus="modifyStatus($event)"
            @modify="detail($event, 'modify')"
            @addCabin="addCabin($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
import { isValid, storeList, storeDetail, storeInfoCheck, storeInfoModify, createPlatformShop, createCabin } from '../../utils/api'
import { assginDataToArray, geneIndex, isEmpty } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '修改', eventName: 'modify'},
                 {text: '审批', eventName: 'modifyStatus'},
                 {text: '新增', eventName: 'addCabin'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '店主姓名', dataIndex: 'realName', key: 'realName'},
                 {title: '店主电话', dataIndex: 'phone', key: 'phone'},
                 {title: '店铺名称', dataIndex: 'shopName', key: 'nickName'},
                 {title: '店铺ID', dataIndex: 'id', key: 'id'},
                 {title: '彩票类型', dataIndex: 'ticketTypeText', key: 'ticketTypeText'},
                 {title: '上级代理', dataIndex: 'parentRealName', key: 'parentRealName'},
                 {title: '开店时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '店铺名称', value: '', },
                 {type: 'selector', key: 'value', options: [{label: '待审核', value: '0'},{label: '已驳回', value: '1'}, {label: '审核通过', value: '10'}, {label: '已关店', value: '20'}], label: '状态', value: undefined},
                 {type: 'button', eventName: 'createShop', label: '创建自营店'},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
             ],
             checkForms: [
                 {label: '店铺状态', type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '待审核', value: '0'}, {labelName: '审核通过', value: '10'}, {labelName: '审核驳回', value: '1'}, {labelName: '已关店', value: '20'}]},
                 {label: '留言', type: 'input', value: '', prop: 'remark'}
             ],
             platformShop: [
                {label: '店铺名', prop: 'name', type: 'input', value: '',},
                {label: '彩票类型', prop: 'ticketType', type: 'selector', value: '', key: 'value', options: [{labelName: '体彩', value: 'TC'},{labelName: '福彩', value: 'FC'}]},
                {label: '联系人姓名', prop: 'storeContactName', type: 'input', value: '',},
                {label: '联系人手机', prop: 'storeContactPhone', type: 'input', value: '',},
                {label: '省', prop: 'province', type: 'input', value: '',},
                {label: '市', prop: 'city', type: 'input', value: '',},
                {label: '区', prop: 'county', type: 'input', value: '',},
                {label: '详细地址', prop: 'address', type: 'input', value: '',},
             ],
             cabinsInfo: [
                {label: '小屋编号', value: '', type: 'input'},
                {label: '小屋状态', type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '初始化', value: '0'},{labelName: '已上架', value: '1'},{labelName: '已下架', value: '2'}, {labelName: '已删除', value: '3'}]},
                {label: '小屋备注', value: '', type: 'input'},
                {label: '设备1编号', value: '', type: 'input', eventName: 'add', eventLabel: '添加'},
                {label: '设备1备注', value: '', type: 'input'},
             ],
             popForms: [
                 {label: '店铺编号', prop: 'id', value: '', type: 'input'},
                 {label: '店主姓名', prop: 'realName', value: '', type: 'input'},
                 {label: '联系电话', prop: 'phone', value: '', type: 'input'},
                 {label: '店铺名称', prop: 'shopName', value: '', type: 'input'},
                 {label: '所在地址', props: ['province', 'city', 'county'], value: '', type: 'input'},
                 {label: '详细地址', prop: 'address', value: '', type: 'input'},
                 {label: '店铺状态', prop: 'statusText', value: '', type: 'input'},
                 {label: '彩票类型', prop: 'ticketTypeText', value: '', type: 'input'},
                 {label: '备注', prop: 'remark', value: '', type: 'input'},
                 {label: '经度地址', prop: 'longitude', value: '', type: 'input'},
                 {label: '纬度地址', prop: 'latitude', value: '', type: 'input'},
            ],
            eidtForms:[
                 {label: '店铺名称', prop: 'shopName', value: '', type: 'input'},
                 {label: '店主姓名', prop: 'realName', value: '', type: 'input'},
                 {label: '联系电话', prop: 'phone', value: '', type: 'input'},
                 {label: '店铺所在省', prop: 'province', value: '', type: 'input'},
                 {label: '店铺所在市', prop: 'city', value: '', type: 'input'},
                 {label: '店铺所在区', prop: 'county', value: '', type: 'input'},
                 {label: '详细地址', prop: 'address', value: '', type: 'input'},
                 {label: '备注', prop: 'remark', value: '', type: 'input'},
            ],
            modifyItems: [
                {label: '单据状态', type: 'selector', value: '', options: [], key: 'name',},
                {label: '备注', type: 'input', value: ''}
            ],
            isEdit: false,
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             selectedIDs: [],
             loading: false,
             status: 0,
             detail: {},
             tableRow: {},
             showCheckDialog: false,
             popType: 0, // ) 0:eidtForms 1:platformShop 2: cabinsInfo
        })
        const showPopForms = computed(() => {
            return state.isEdit ? state.popType == 0 ? state.eidtForms 
            : state.popType == 2 ? state.cabinsInfo : state.platformShop
                 : state.popForms
        })
        const title = computed(() => {
            const type = state.popType
            return type == 0 ? '修改' : type == 2 ? '添加小屋' : '自营店信息'
        })
        function addCabin(event) {
            state.tableRow = event
            console.log(state.tableRow)
            state.isEdit = true 
            state.popType = 2 
            state.showDialog = true
            state.cabinsInfo = state.cabinsInfo.splice(0, 5)
            state.cabinsInfo.forEach(ele => ele.value = '')
        }
        function createShop() {
            state.isEdit = true
            state.popType = 1
            state.platformShop.forEach(ele => ele.value = '')
            state.showDialog = true
        }
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    id: '',
                    name: state.formItems[0].value,
                    statusType: state.formItems[1].value,
                }
                const result = await storeList(params)
                state.loading = false
                if(isValid(result)) {
                    state.tableList = geneIndex(result.data.shopList, state.pageSize, state.currentPage)
                    state.total = result.data.total
                    state.tableList.forEach(ele => {
                        ele.ticketTypeText = ele.ticketType == 'TC' ? '体彩' : '福彩'
                        ele.statusText = ele.status == 0 ? '待审核' : ele.status == 20 ? '已关店' : ele.status == 10 ? '审核通过' : '已驳回'
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        function modifyStatus(event) {
            state.title = '店铺信息修改'
            state.detail = event
            state.tableRow = event
            state.checkForms.forEach(ele => {
                ele.value = event[ele.prop]
            })
            state.showCheckDialog = true
        }
        async function detail(event, type="detail") {
            try {
                state.title = '店铺详情'
                state.tableRow = event
                const result = await storeDetail(event.id)
                if (isValid(result)) {
                    const ele = result.data
                    state.detail = result.data
                    ele.ticketTypeText = ele.ticketType == 'TC' ? '体彩' : '福彩'
                    ele.statusText = ele.status == 0 ? '待审核' : ele.status == 20 ? '已关店' : ele.status == 10 ? '审核通过' : '已驳回'
                    if (type == 'detail') {
                        state.popForms = assginDataToArray(result.data, state.popForms)
                    } else {
                        state.popType = 0
                        state.eidtForms = assginDataToArray(result.data, state.eidtForms)
                    }
                    state.isEdit = type == 'modify'
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }

        async function check() {
            try {
                const id = state.detail.id 
                const items = state.checkForms
                const result = await storeInfoCheck({
                    shopId: id,
                    status: items[0].value,
                    remark: items[1].value
                })
                if (isValid(result)) {
                    message.success('修改成功')
                    // loadData()
                    const s = items[0].value
                    state.tableRow.status = s
                    state.tableRow.statusText = s == 0 ? '待审核' : s == 20 ? '已关店'  : s == 10 ? '审核通过' : '已驳回'
                    state.showCheckDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }

        function arrayToObject(arr) {
            const obj = {}
            arr.forEach(ele => {
                if (ele.prop) {
                    obj[ele.prop] = ele.value
                }
            })
            return obj
        }

        function getDeviceCabinList(items) {
            const list = []
            for(let i = 3, l = items.length;  i < l; i+=2) {
                list.push({
                    deviceNo: items[i].value,
                    remark: items[i+1].value
                })
                // i = i+2
            }
            return list
        }
       
        async function closeDialog() {
            try {
                console.log(state.popType)
                if (state.isEdit) {
                    if (state.popType == 0) {
                        const items = state.eidtForms 
                        const params = arrayToObject(items)
                        params.shopId = state.detail.id
                        const result = await storeInfoModify(params)
                        if (isValid(result)) {
                            message.success('修改成功')
                            loadData()
                            state.showDialog = false
                        }
                    } else if(state.popType == 1) {
                        const items = state.platformShop
                        const params = arrayToObject(items)
                        const result = await createPlatformShop(params)
                        if (isValid(result)) {
                            message.success('创建成功')
                            loadData()
                            state.showDialog = false
                        }
                    } else {
                        const items = state.cabinsInfo
                        if (isEmpty(items)) {
                            return
                        }

                        const params = {
                            shopId: state.tableRow.id,
                            cabinNo: items[0].value,
                            status: items[1].value,
                            remark: items[2].value,
                            deviceCabinList: getDeviceCabinList(items)
                        }
                        const result = await createCabin(params)
                        if (isValid(result)) {
                            message.success('创建成功')
                            state.showDialog = false
                        }
                        
                    }
                } else {
                    state.showDialog = false
                }
            } catch(e) {
                console.error(e)
            }
            // loadData()
            // state.showDialog = false
        }
        function pageChange(event) {
            state.currentPage = event
            loadData()
        }


        function addMachine() {
            const l = state.cabinsInfo.length
            state.cabinsInfo[l-2].eventLabel = '删除'
            state.cabinsInfo[l-2].eventName = 'delete'
            const mIndex = parseInt((l/2)) 
            state.cabinsInfo.push({
                label: `设备${mIndex}编号`,
                value: '', 
                type: 'input', 
                eventName: 'add', 
                eventLabel: '添加'
            })
            state.cabinsInfo.push({
                label: `设备${mIndex}备注`,
                value: '', 
                type: 'input', 
            })
        }

        function deleteMachine(index) {
            state.cabinsInfo.splice(index, 2)
            
            state.cabinsInfo.forEach((ele, eleIndex) => {
                if (eleIndex > 2) {
                    const l = parseInt((eleIndex-1)/2)
                    ele.label = `设备${l} ${(eleIndex-1)%3? '编号' : '备注'}`
                }
            })
        }
        
        return {
            ...toRefs(state),
            showPopForms,
            detail,
            loadData,
            modifyStatus,
            closeDialog,
            check,
            pageChange,
            createShop,
            addCabin,
            title,
            addMachine,
            deleteMachine
        }
    },
}
</script>
<style lang='scss' scoped>
</style>